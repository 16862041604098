import HeaderTop from "../../components/header-top/header-top.component";
import Banner from "../../components/banner/banner.component";
import Navbar from "../../components/navbar/navbar.component";
import Mission from "../../components/mission/mission.component";
import Offshore from "../../components/offshore/offshore.component";

import Headerbottom from "../../components/header-bottom/header-bottom.component";

import NavMenu from "../../components/nav-menu/nav-menu.component";

import { Container } from "react-bootstrap";

import "./headerOffshore.styles.scss";

const companyName = process.env.REACT_APP_ENVIRONMENT === 'demt' ? "Digital Era Management & Technology" : "DigiQ Technologies"

const homepage = {
  heading:
    "Making offshoring effective",
  subtext:
    `We leverage the latest techniques in software development to enable rapid delivery with high quality and low cost`,
};

const contactpage = {
  heading: "Get in touch",
  subtext: "Keeping up means you gotta go fast.",
};

const HeaderOffshore = ({ toggleMenu, setToggleMenu, contact }) => {
  return (
    <div className="headerOffshore">
      <Container>
        <Navbar setToggleMenu={setToggleMenu} toggleMenu={toggleMenu}></Navbar>
        {toggleMenu ? (
          <NavMenu setToggleMenu={setToggleMenu}></NavMenu>
        ) : (
            <>
              <HeaderTop contact={contact ? true : false}></HeaderTop>

              {!contact ? (
                <>
                  <Banner
                    heading={homepage.heading}
                    subtext={homepage.subtext}
                    contact={true}
                  ></Banner>

                </>
              ) : (
                  <>
                    <Banner
                      heading={contactpage.heading}
                      subtext={contactpage.subtext}
                      contact
                    ></Banner>
                  </>
                )}
              <Headerbottom contact={contact ? true : false}></Headerbottom>
            </>
          )}
      </Container>
    </div>
  );
};

export default HeaderOffshore;
