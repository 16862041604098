import { combineReducers } from "redux";
// import todoReducer from "./todo/todoReducer";
// import userReducer from "./user/userReducer";
import { firestoreReducer } from "redux-firestore";

const Reducers = combineReducers({
  // user: userReducer,
  // todo: todoReducer,
  firestore: firestoreReducer,
});

export default Reducers;
