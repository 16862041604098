import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/analytics";

// const config = {
//   apiKey: "AIzaSyCeZT4uIknCDsm-Xz4Wie0118Fo-R0EmuE",
//   authDomain: "digiq-webpage.firebaseapp.com",
//   projectId: "digiq-webpage",
//   storageBucket: "digiq-webpage.appspot.com",
//   messagingSenderId: "415187046622",
//   appId: "1:415187046622:web:64bf80b7e1809422fc724f",
//   measurementId: "G-FX02K7E5X3"
// };

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_API_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}



firebase.initializeApp(config);
export const auth = firebase.auth();
export const firestore = firebase.firestore();

export default firebase;
