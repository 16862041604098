import curvetwo from "../../assets/whoarewecurve2.svg";
import whitecurvemobile1 from "../../assets/whitecurvemobile1.svg";

import whitecurvemobile2 from "../../assets/whitecurvemobile2.svg";
import { useEffect, useState } from "react";
import { isMobile } from "../../utils/getScreenSize";

import "./who-are-we-curve.styles.scss";

const WhoAreWeCurve = () => {
  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    const temp = isMobile();
    setMobile(temp);
  }, []);
  return (
    <div className="who-are-we-curve">
      {mobile ? (
        <>
          <img src={whitecurvemobile1} alt="curve1" />
          <img src={whitecurvemobile2} alt="curve2" />{" "}
        </>
      ) : (
        <>
          <img src={curvetwo} alt="curve1" />
          <img src={curvetwo} alt="curve2" />
        </>
      )}
    </div>
  );
};

export default WhoAreWeCurve;
