import Heading from "../heading/heading.component";

import SubText from "../sub-text/sub-text.component";
import "./offshore.styles.scss";

const Offshore = () => {
  return (
    <div className="offshore">
      <Heading h2={true}>Offshoring that makes sense</Heading>
      <SubText>
        We help our clients build their product offshore, low cost capability without compromising on quality or speed.
        We do this through delivering that can be validated on a daily basis and leverage the latest techniques in
        software development. For more details on our streamlined approach click here.
      </SubText>
    </div>
  );
};

export default Offshore;
