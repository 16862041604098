import Heading from "../heading/heading.component";
import ButtonPrimary from "../button-primary/button-primary.component";

import "./banner.styles.scss";
import SubText from "../sub-text/sub-text.component";

const Banner = ({ heading, subtext, contact }) => {
  return (
    <div className={`banner ${contact ? "contact" : ""} `}>
      <Heading h1={true}>{heading}</Heading>
      <SubText>{subtext}</SubText>
      {!contact ? <ButtonPrimary>Maybe Even You?</ButtonPrimary> : ""}{" "}
    </div>
  );
};

export default Banner;
