import ProjectListItem from "../project-list-item/project-list-item.component";
import "./project-list.styles.scss";
import swm from "../../assets/swm.png";
import fa from "../../assets/fa.png";
import infaque from "../../assets/infaque.png";
import insurance from "../../assets/insurance.png";

const ProjectData = [
  {
    heading: "Small World Connect",
    subtext:
      "A nonprofit software product focused on connecting musicians, organizers, and venues across the world.",
    image: swm,
  },
  {
    heading: "Financial Acumen",
    subtext:
      "A budgeting and planning tool built to make good financial analysis more accessible to nonprofit and small business leaders.",
    image: fa,
  },
  {
    heading: "Infāque",
    subtext:
      "A direct-to-consumer giving platform that connects contributors to the causes they care about—and funnels donations to nonprofits.",
    image: infaque,
  },
  {
    heading: "Large US Insurance Company",
    subtext:
      "A change-management process that empowered employees to get things done faster and more sustainably.",
    image: insurance,
  },
];
const ProjectList = () => {
  return (
    <div className="project-list">
      {ProjectData.map((data, i) => (
        <ProjectListItem
          key={i}
          number={i + 1}
          heading={data.heading}
          subtext={data.subtext}
          url={data.image}
        ></ProjectListItem>
      ))}
    </div>
  );
};
export default ProjectList;
