import Navbar from "../../components/navbar/navbar.component";
import { Container } from "react-bootstrap";
import "./footer.styles.scss";
import d from "../../assets/d.svg";
import NavMenu from "../../components/nav-menu/nav-menu.component";

const Footer = ({ toggleMenu, setToggleMenu }) => {
  return (
    <div className="footer" style={{ backgroundImage: `url(${d})` }}>
      <Container>
        <Navbar
          footer={true}
          setToggleMenu={setToggleMenu}
          toggleMenu={toggleMenu}
        ></Navbar>
        {toggleMenu ? <NavMenu setToggleMenu={setToggleMenu}></NavMenu> : ""}
      </Container>
    </div>
  );
};

export default Footer;
