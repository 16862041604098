import "./result-list.styles.scss";
import ResultListItems from "../result-list-items/result-list-items.component";
import { Col, Row } from "react-bootstrap";

const ResultData = [
  {
    heading: "Transformational Change",
    subtext: (
      <>
        {" "}
        We deliver transformational change
        <span className="orange"> incrementally</span>. When things happen too
        quickly people can be sent into shock. We take change one step at a
        time—leading to better organizations and happier people.
      </>
    ),
  },
  {
    heading: "Early ROI",
    subtext: (
      <>
        {" "}
        We create real
        <span className="orange"> value</span>, often. Whether it’s
        organizational change or a live piece of software—we’re here to show a
        clear Return on Investment, quickly. We don’t bog down in planning—we
        deliver.
      </>
    ),
  },
  {
    heading: "Affordable Outcomes",
    subtext: (
      <>
        {" "}
        Whether it’s internal systems, or technology—we’re experts at staying
        well-scoped and affordable.
        <span className="orange"> We cut out bloat</span> from the process and
        defining exactly what’s necessary with clarity and focus.
      </>
    ),
  },
];
const ResultList = () => {
  return (
    <div className="result-list">
      <Row>
        {ResultData.map((data, i) => (
          <Col className="list-item-container" key={i} lg={4}>
            <ResultListItems
              heading={data.heading}
              subtext={data.subtext}
            ></ResultListItems>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ResultList;
