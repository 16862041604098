import "./nav-menu.styles.scss";
import { Container } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

const NavMenu = ({ setToggleMenu }) => {
  const history = useHistory();
  const route = history.location.pathname;
  return (
    <div className="nav-menu-wrapper">
      <Container className="nav-menu">
        <Link
          onClick={() => setToggleMenu(false)}
          className={route === "/" ? "active" : ""}
          to="/"
        >
          Home
        </Link>
        <Link
          onClick={() => setToggleMenu(false)}
          className={route === "/offshore" ? "active" : ""}
          to="/offshore"
        >
          Offshore
        </Link>
        <Link
          onClick={() => setToggleMenu(false)}
          className={route === "/contact" ? "active" : ""}
          to="/contact"
        >
          Contact
        </Link>
      </Container>
    </div>
  );
};
export default NavMenu;
