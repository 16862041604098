import CharacteristicsOffshore from "../../sections/characteristicsOffshore/characteristicsOffshore.component";

import Results from "../../sections/results/results.component";
import HeaderOffshore from "../../sections/headerOffshore/headerOffshore.component";
import Projects from "../../sections/projects/projects.component";
import WhoAreWe from "../../components/who-are-we/who-are-we.component";

import "./offshore.styles.scss";
import Footer from "../../sections/footer/footer.component";

const OffshorePage = ({ toggleMenu, setToggleMenu }) => {
  return (
    <div className="offshore-page">
      <HeaderOffshore toggleMenu={toggleMenu} setToggleMenu={setToggleMenu}></HeaderOffshore>
      {!toggleMenu ? (
        <>
          <CharacteristicsOffshore></CharacteristicsOffshore>
          <WhoAreWe />
          <Footer
            toggleMenu={toggleMenu}
            setToggleMenu={setToggleMenu}
          ></Footer>{" "}
        </>
      ) : (
          ""
        )}
    </div>
  );
};

export default OffshorePage;
