import { useEffect, useState } from "react";
import { isTablet } from "../../utils/getScreenSize";

import whitecurve from "../../assets/whitecurve.png";
import bottomcurveonemobile from "../../assets/bottomcurveonemobile.svg";
import bottomcurvetwomobile from "../../assets/bottomcurvetwomobile.svg";

// import bottomcurveone from "../../assets/bottomcurveone.svg";
// import bottomcurvetwo from "../../assets/bottomcurvetwo.svg";

import "./header-bottom.styles.scss";

const Headerbottom = ({ contact }) => {
  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    const temp = isTablet();
    setMobile(temp);
  }, []);
  return (
    <div className="header-bottom">
      {!mobile || contact ? (
        <img
          src={whitecurve}
          className={`white-curve ${contact ? "white-curve-contact" : ""}`}
          alt="white curve"
        />
      ) : (
        <>
          <img src={bottomcurveonemobile} alt="curve1" />
          <img src={bottomcurvetwomobile} alt="curve2" />
        </>
      )}
    </div>
  );
};

export default Headerbottom;
