import "./heading.styles.scss";

const Heading = ({ h1, children, h2, h3, h4, bold }) => {
  return (
    <div className={`${bold ? "bold" : ""} heading `}>
      {h1 ? <h1>{children}</h1> : ""}
      {h2 ? <h2>{children}</h2> : ""}
      {h3 ? <h3>{children}</h3> : ""}
      {h4 ? <h4>{children}</h4> : ""}
    </div>
  );
};
export default Heading;
