import "./button-secondary.styles.scss";

const ButtonSecondary = ({ children, ...props }) => {
  return (
    <div className="button-secondary">
      <button {...props}>{children}</button>
    </div>
  );
};
export default ButtonSecondary;
