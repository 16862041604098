import Heading from "../heading/heading.component";
import SubText from "../sub-text/sub-text.component";
import "./about.styles.scss";
const About = () => {
  return (
    <div className="about">
      <Heading h2>We're system builders who can code</Heading>
      <SubText>
        We're founded on a history of creating efficient systems for large
        organizations. This means we can build technology without all of the
        bloat.
      </SubText>
    </div>
  );
};
export default About;
