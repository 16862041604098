import { Col } from "react-bootstrap";
import About from "../../components/about/about.component";
import CharacteristicList from "../../components/characteristic-list/characteristic-list.component";
import "./characteristics.styles.scss";

const Characteristics = () => {
  return (
    <div className="row container characteristics">
      <Col className="about-wapper" lg={4}>
        <About></About>
      </Col>
      <Col className="list-wrapper" lg={8}>
        <CharacteristicList></CharacteristicList>
      </Col>
    </div>
  );
};

export default Characteristics;
