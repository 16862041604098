import Heading from "../heading/heading.component";
import SubText from "../sub-text/sub-text.component";
import "./aboutOffshore.styles.scss";
const About = () => {
  return (
    <div className="aboutOffshore">
      <Heading h2>How we offshore</Heading>
      <SubText>
        We make our work extreamly transparent, leverage DevOps and Kanban techniques and optimize for continous flow of delivery. We do ask that you spend time with the team daily and be sure of what you want to get built.
      </SubText>
    </div>
  );
};
export default About;
