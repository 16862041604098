import { Container } from "react-bootstrap";

import Heading from "../../components/heading/heading.component";
import ResultList from "../../components/result-list/result-list.component";

import WhoAreWe from "../../components/who-are-we/who-are-we.component";
import "./results.styles.scss";
const Results = () => {
  return (
    <div className="results-wrapper">
      <div className="results">
        <Container>
          <Heading h2>Our results.</Heading>
          <ResultList></ResultList>
        </Container>
      </div>
      <WhoAreWe></WhoAreWe>
    </div>
  );
};
export default Results;
