import { createStore, applyMiddleware, compose } from "redux";

import logger from "redux-logger";
import thunk from "redux-thunk";

import fbConfig from "../firebase/firebase.utlis";
import firebase from "firebase/compat/app";
import rootReducer from "./rootReducer";

import {
  createFirestoreInstance,
  reduxFirestore,
  getFirestore,
} from "redux-firestore";
import { getFirebase } from "react-redux-firebase";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(
      thunk.withExtraArgument({ getFirebase, getFirestore }),
      logger
    ),
    reduxFirestore(firebase, fbConfig)
  )
);

export const rrfProps = {
  firebase,
  config: fbConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

export default store;
