import "./characteristic-list.styles.scss";
import CharacteristicListItems from "../characteristic-list-items/characteristic-list-items.component";
import focused from "../../assets/focused.svg";
import responsive from "../../assets/responsive.svg";
import adaptable from "../../assets/adaptable.svg";

const CharacteristicData = [
  {
    heading: "Focused",
    image: focused,
    subtext:
      "We focus on making the impact you are looking for as quickly and sustainaibly as possible. This is how we eliminate bloat.",
  },
  {
    heading: "Responsive",
    image: responsive,
    subtext:
      "Our approach is tied to human wants and needs. Before we start to build—we listen. Our ethos puts you and your user above anything else.",
  },
  {
    heading: "Adaptable",
    image: adaptable,
    subtext:
      "We'd rather show you live software than another plan—so we deliver early an often. This way we can be adaptable to change.",
  },
];
const CharacteristicList = () => {
  return (
    <div className="characteristic-list">
      {CharacteristicData.map((data, i) => (
        <CharacteristicListItems
          key={i}
          heading={data.heading}
          subtext={data.subtext}
          image={data.image}
        ></CharacteristicListItems>
      ))}
    </div>
  );
};

export default CharacteristicList;
