import { Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ButtonSecondary from "../../components/button-secondary/button-secondary.component";
import Heading from "../../components/heading/heading.component";
import ProjectList from "../../components/project-list/project-list.component";
import "./projects.styles.scss";

const Projects = () => {
  const history = useHistory();
  return (
    <div className="projects">
      <Container>
        <Heading h2>Featured Projects </Heading>
        <ProjectList></ProjectList>
        <Row className="text-center">
          <ButtonSecondary onClick={() => history.push("/contact")}>
            Contact Us
          </ButtonSecondary>
        </Row>
      </Container>
    </div>
  );
};
export default Projects;
