import Characteristics from "../../sections/characteristics/characteristics.component";

import Results from "../../sections/results/results.component";
import Header from "../../sections/header/header.component";
import Projects from "../../sections/projects/projects.component";

import "./home.styles.scss";
import Footer from "../../sections/footer/footer.component";

const HomePage = ({ toggleMenu, setToggleMenu }) => {
  return (
    <div className="home-page">
      <Header toggleMenu={toggleMenu} setToggleMenu={setToggleMenu}></Header>
      {!toggleMenu ? (
        <>
          <Characteristics></Characteristics>
          <Results></Results>
          <Projects></Projects>
          <Footer
            toggleMenu={toggleMenu}
            setToggleMenu={setToggleMenu}
          ></Footer>{" "}
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default HomePage;
