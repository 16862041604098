import { useState, useEffect } from "react";
import { firestore } from "../../firebase/firebase.utlis";
import { Col, Container, Row } from "react-bootstrap";
import { mailFormat } from "../../utils/regularExpressions";
import { nameFormat } from "../../utils/regularExpressions";
import InputField from "../../components/input-field/input-field.component";
import "./contact-form.styles.scss";

const initial_state = {
  firstName: "",
  lastName: "",
  email: "",
  subject: "",
  message: "",
};
const initial_state_err = {
  firstName: true,
  lastName: true,
  email: true,
  subject: true,
  message: true,
};
const ContactForm = () => {
  const [formData, setFormData] = useState(initial_state);

  const [error, setError] = useState(initial_state_err);

  const [disabled, setDisabled] = useState(true);

  const handleChange = (event) => {
    const { value, name } = event.target;
    if (value !== "") {
      setError({ ...error, [name]: false });
      if (name === "email") {
        if (value.match(mailFormat)) {
          setError({ ...error, [name]: false });
        } else {
          setError({ ...error, [name]: true });
        }
      }
      if (name === "firstName" || name === "lastName") {
        if (value.match(nameFormat)) {
          setError({ ...error, [name]: false });
        } else {
          setError({ ...error, [name]: true });
        }
      }
    } else {
      setError({ ...error, [name]: true });
    }
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (
      !error.firstName &&
      !error.lastName &&
      !error.email &&
      !error.subject &&
      !error.message
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [error]);

  const handleClick = async () => {
    if (disabled) {
      return;
    }
    try {
      let userRef = firestore.collection("contact-us");
      const contact = await userRef.add({
        ...formData,
        createdAt: new Date(),
      });

      console.log("Data entered successfully");
      setFormData(initial_state);
      setError(initial_state_err);
      if (contact) {
      } else {
        const errorObject = {
          status: 500,
          message: "Error in writing contact",
        };
        throw errorObject;
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div className="contact-form">
      <Container>
        <Row>
          <Col lg={6}>
            <InputField
              onChange={handleChange}
              value={formData.firstName}
              name="firstName"
              placeholder="First Name"
            ></InputField>
          </Col>
          <Col lg={6}>
            <InputField
              onChange={handleChange}
              name="lastName"
              value={formData.lastName}
              placeholder="Last Name"
            ></InputField>
          </Col>
        </Row>
        <InputField
          onChange={handleChange}
          name="email"
          value={formData.email}
          placeholder="Email Address"
        ></InputField>
        <InputField
          onChange={handleChange}
          name="subject"
          placeholder="Subject"
          value={formData.subject}
        ></InputField>
        <InputField
          onChange={handleChange}
          name="message"
          textarea
          value={formData.message}
          placeholder="Message"
        ></InputField>

        <button
          onClick={handleClick}
          className={`contact-button ${disabled ? "disabled" : ""}`}
        >
          Submit
        </button>
      </Container>
    </div>
  );
};

export default ContactForm;
