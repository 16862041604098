import { Col } from "react-bootstrap";
import Heading from "../heading/heading.component";
import SubText from "../sub-text/sub-text.component";
import "./characteristic-list-items.styles.scss";
const CharacteristicListItems = ({ heading, image, subtext }) => {
  return (
    <div className="characteristic-list-items-wrapper">
      <div
        className="characteristic-list-items "
        style={{ backgroundImage: `url(${image})` }}
      >
        <Col className="list-item-wrapper" lg={9}>
          <Heading h3>{heading}</Heading>
          <SubText small>{subtext}</SubText>
          <span className="square"></span>
        </Col>
      </div>
    </div>
  );
};

export default CharacteristicListItems;
