import { Col, Row } from "react-bootstrap";
import Heading from "../heading/heading.component";
import SubText from "../sub-text/sub-text.component";
import "./project-list-item.styles.scss";

const ProjectListItem = ({ number, heading, subtext, url }) => {
  return (
    <div className="project-list-item">
      <Row>
        <Col lg={5}>
          <Heading h1>0{number}</Heading>
          <Heading h3>{heading}</Heading>
          <SubText>{subtext}</SubText>
        </Col>
        <Col lg={1}></Col>
        <Col lg={6}>
          <img src={url} alt={`project ${number}`} />
        </Col>
      </Row>
    </div>
  );
};
export default ProjectListItem;
