import { Col } from "react-bootstrap";
import AboutOffshore from "../../components/aboutOffshore/aboutOffshore.component";
import CharacteristicListOffshore from "../../components/characteristic-list-offshore/characteristicOffshore-list.component";
import "./characteristicsOffshore.styles.scss";

const CharacteristicsOffshore = () => {
  return (
    <div className="row container characteristics">
      <Col className="about-wapper" lg={4}>
        <AboutOffshore></AboutOffshore>
      </Col>
      <Col className="list-wrapper" lg={8}>
        <CharacteristicListOffshore />
      </Col>
    </div>
  );
};

export default CharacteristicsOffshore;
