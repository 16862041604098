import Heading from "../heading/heading.component";

import SubText from "../sub-text/sub-text.component";
import "./mission.styles.scss";

const Mission = () => {
  return (
    <div className="mission">
      <Heading h2={true}>Our Mission</Heading>
      <SubText>
        Our mission is to build nimble technologies and systems that respond to
        real human needs. Right now, technology is too expensive because we
        approach building it with rigidity. But in the digital era, there are
        ways to make tech accessible to more organizations—while keeping
        employees and users happy.
      </SubText>
    </div>
  );
};

export default Mission;
