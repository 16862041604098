import { useState } from "react";
import HomePage from "./pages/home/home.component";
import ContactPage from "./pages/contact/contact.component";
import OffshorePage from "./pages/offshore/offshore.component";
import { Switch, Route } from "react-router-dom";
import "./App.css";

function App() {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className="App">
      <Switch>
        <Route
          exact
          path="/"
          component={() => (
            <HomePage toggleMenu={toggleMenu} setToggleMenu={setToggleMenu} />
          )}
        ></Route>
        <Route
          exact
          path="/contact"
          component={() => (
            <ContactPage
              toggleMenu={toggleMenu}
              setToggleMenu={setToggleMenu}
            />
          )}
        ></Route>
        <Route
          exact
          path="/offshore"
          component={() => (
            <OffshorePage toggleMenu={toggleMenu} setToggleMenu={setToggleMenu} />
          )}
        ></Route>
      </Switch>
    </div>
  );
}

export default App;
