import "./characteristicOffshore-list.styles.scss";
import CharacteristicListItems from "../characteristic-list-items/characteristic-list-items.component";
import devops from "../../assets/devops.svg";
import planner from "../../assets/planner.svg";
import sketch from "../../assets/sketch.svg";
import adaptable from "../../assets/adaptable.svg";

const CharacteristicData = [
  {
    heading: "Demonstrate Value Daily",
    image: planner,
    subtext:
      "The team will spend an up to hour with you daily showing you what was completed and planning the next day's activites",
  },
  {
    heading: "DevOps",
    image: devops,
    subtext:
      "We will automate our testing and deployment allowing the team to focus solely on getting the product you want built in your hands as quickly as possible",
  },
  {
    heading: "Simplify the Architecture",
    image: sketch,
    subtext:
      "We leverage serverless architecture and minimize the number of technologies required.",
  },
];
const CharacteristicListOffshore = () => {
  return (
    <div className="characteristic-list-offshore">
      {CharacteristicData.map((data, i) => (
        <CharacteristicListItems
          key={i}
          heading={data.heading}
          subtext={data.subtext}
          image={data.image}
        ></CharacteristicListItems>
      ))}
    </div>
  );
};

export default CharacteristicListOffshore;
