import Heading from "../heading/heading.component";
import SubText from "../sub-text/sub-text.component";
import "./result-list-items.styles.scss";
const ResultListItems = ({ heading, subtext }) => {
  return (
    <div className="result-list-items">
      <Heading h4>{heading}</Heading>
      <SubText small>{subtext}</SubText>
    </div>
  );
};

export default ResultListItems;
