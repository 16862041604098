import contactorangecurve from "../../assets/contactorangecurve.png";
import orangecurve from "../../assets/orangecurve.png";

import "./header-top.styles.scss";

const HeaderTop = ({ contact }) => {
  return (
    <div className="header-top">
      <img
        src={!contact ? orangecurve : contactorangecurve}
        className={contact ? "contact-orange-curve" : ""}
        alt="orangecurve"
      />
    </div>
  );
};

export default HeaderTop;
