import { useState, useEffect } from "react";
import { Col, Row, Container } from "react-bootstrap";

import connect from "../../assets/connect.svg";
import SubText from "../../components/sub-text/sub-text.component";
import fahadheadshot from "../../assets/fahadheadshot.png";
import firebase from "../../firebase/firebase.utlis";
import Heading from "../heading/heading.component";

import { isTablet } from "../../utils/getScreenSize";
import "./who-are-we.styles.scss";
import WhoAreWeCurve from "../who-are-we-curve/who-are-we-curve.component";
const WhoAreWe = () => {
  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    const temp = isTablet();
    setMobile(temp);
  }, []);

  return (
    <div className="who-are-we">
      <WhoAreWeCurve></WhoAreWeCurve>
      <Container>
        <Row className="leader-wrapper">
          {mobile ? (
            <Col className="text-center" lg={7}>
              <img className="leader" src={fahadheadshot} alt="fahad"></img>
            </Col>
          ) : (
            ""
          )}
          <Col className="connect-wrapper" lg={5}>
            <Heading h2>Who are we to pull this off?</Heading>
            <SubText>
              Fahad Qureshi is a leader in the Business Agility space and a
              technologist that has spent the last two decades honing software
              development & business processes for some of the world’s largest
              companies and most impactful nonprofits.
            </SubText>
            <div className="connect">
              <img className="linked-in" src={connect} alt="linkedin" />
              <div className="anchor">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/in/qureshifahad/"
                  onClick={() => {
                    firebase.analytics().logEvent("visit_linkedin", "linkedin");
                  }}
                >
                  Let's connect!
                </a>
              </div>
            </div>
          </Col>
          {!mobile ? (
            <Col className="leader-image" lg={7}>
              <img className="leader" src={fahadheadshot} alt="fahad"></img>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </Container>
    </div>
  );
};
export default WhoAreWe;
