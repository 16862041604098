import "./button-primary.styles.scss";

const ButtonPrimary = ({ children }) => {
  return (
    <div className="button-primary">
      <button>{children}</button>
    </div>
  );
};
export default ButtonPrimary;
