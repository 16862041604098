import ContactForm from "../../sections/contact-form/contact-form.component";
import Header from "../../sections/header/header.component";
import Footer from "../../sections/footer/footer.component";
import "./contact.styles.scss";
const Contact = ({ toggleMenu, setToggleMenu }) => {
  return (
    <div>
      <Header
        toggleMenu={toggleMenu}
        setToggleMenu={setToggleMenu}
        contact
      ></Header>
      {!toggleMenu ? (
        <>
          <ContactForm></ContactForm>
          <Footer
            toggleMenu={toggleMenu}
            setToggleMenu={setToggleMenu}
          ></Footer>
        </>
      ) : (
        ""
      )}
    </div>
  );
};
export default Contact;
