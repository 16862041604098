import "./sub-text.styles.scss";

const SubText = ({ children, small }) => {
  return (
    <div className={`sub-text ${small ? "small" : ""}`}>
      <p>{children}</p>
    </div>
  );
};

export default SubText;
