import "./input-field.styles.scss";

const InputField = ({ placeholder, textarea, ...props }) => {
  return (
    <div className="input-field">
      {!textarea ? (
        <input {...props} placeholder={placeholder} />
      ) : (
        <textarea {...props} placeholder={placeholder}></textarea>
      )}
    </div>
  );
};
export default InputField;
