export const isTablet = () => {
  let isTablet = window.matchMedia(
    "only screen and (max-width: 769px)"
  ).matches;
  return isTablet;
};

export const isMobile = () => {
  let isMobile = window.matchMedia(
    "only screen and (max-width: 429px)"
  ).matches;
  return isMobile;
};
