import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import Logo from "../../components/logo/logo.component";
import menu from "../../assets/menu.svg";
import footermenu from "../../assets/footermenu.svg";
import cross from "../../assets/cross.svg";
import xsquare from "../../assets/xsquare.svg";

import { isTablet } from "../../utils/getScreenSize";
import "./navbar.styles.scss";

const Navbar = ({ footer, setToggleMenu, toggleMenu }) => {
  const history = useHistory();
  const route = history.location.pathname;

  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    const temp = isTablet();
    setMobile(temp);
  }, []);
  return (
    <div className="navbar">
      <Logo
        onClick={() => {
          history.push("/");
          setToggleMenu(false);
        }}
        toggleMenu={toggleMenu}
      ></Logo>
      {!toggleMenu || footer ? (
        footer && !mobile ? (
          <div className="footer-links">
            <Link
              onClick={() => setToggleMenu(false)}
              className={route === "/" ? "active" : ""}
              to="/"
            >
              Home
            </Link>
            <Link
              onClick={() => setToggleMenu(false)}
              className={route === "/offshore" ? "active" : ""}
              to="/offshore"
            >
              Offshore
            </Link>
            <Link
              onClick={() => setToggleMenu(false)}
              className={route === "/contact" ? "active" : ""}
              to="/contact"
            >
              Contact
            </Link>
          </div>
        ) : (
            <img
              className="toggler"
              onClick={() => setToggleMenu(true)}
              src={footer ? footermenu : menu}
              alt="menu"
            />
          )
      ) : (
          <img
            className="cancel"
            onClick={() => setToggleMenu(false)}
            src={!mobile ? cross : xsquare}
            alt="cross"
          />
        )}
    </div>
  );
};
export default Navbar;
