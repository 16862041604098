import { useState, useEffect } from "react";

import logo_DEMT from "../../assets/logo.svg";
import logo_digiq from "../../assets/DigiQTech1.png"
import logomobile_DEMT from "../../assets/logomobile.svg";
import logomobile_digiq from "../../assets/DigiQTech1_Small.png";

import { isTablet } from "../../utils/getScreenSize";

import "./logo.styles.scss";

let logo;
let logomobile;
if (process.env.REACT_APP_ENVIRONMENT === 'digiq') {
  logo = logo_digiq;
  logomobile = logomobile_digiq;
}
else {
  logo = logo_DEMT;
  logomobile = logomobile_DEMT;
}

const Logo = ({ toggleMenu, onClick }) => {
  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    const temp = isTablet();
    setMobile(temp);
  }, []);

  return (
    <div onClick={onClick} className="logo">
      {!mobile || !toggleMenu ? (
        <img src={logo} className="App-logo" alt="logo" />
      ) : (
        <>
          <img src={logomobile} className="App-logo" alt="logo" />
        </>
      )}
    </div>
  );
};
export default Logo;
